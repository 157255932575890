<template>
  <div>
    <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
      <form
        @submit.prevent="handleSubmit(onSubmitClick)"
        class="needs-validation"
        autocomplete="off"
      >
      <div class="container-fluid">
          <div class="row mt-2 mb-3">
            <div class="col-12" style="display: flex; align-items: center">
              <img
                src="/img/icons/icons8/ios/info-squared_warning.png"
                width="22"
              />
              <h4 class="new-default-black-font m-0 mx-3">Identificação</h4>
              <hr class="ml-3 m-0" style="width: 100%" />
            </div>
          </div>
          <div class="row mt-2 align-items-center">
            <div class="col-6">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Status
              </h4>
            </div>
            <div class="col-2">
              <AppSwitch v-model="formData.status"/>
            </div>
          </div>
          <div class="row mt-2 align-items-center">
            <div class="col-6 mt-1">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Descrição
              </h4>
            </div>
            <div class="col-6">
              <validation-provider rules="required" v-slot="{ errors }">
                <base-input input-group-classes="input-group-sm">
                  <input
                    v-model="formData.name"
                    type="text"
                    class="form-control form-control-sm"
                    :class="errors[0] ? 'is-invalid' : 'is-valid'"
                  />
                </base-input>
              </validation-provider>
            </div>
          </div>
          <div class="row mt-5 mb-4">
            <div class="col-12 text-right">
              <AppButton
                label="Cancelar"
                type="danger"
                class="ml-2"
                :hasOutline="true"
                @click="onCancelClick"
              />
              <AppButton
                label="Salvar"
                type="success"
                class="ml-2"
                native-type="submit"
                :isDisabled="invalid"
                :isLoading="isSaving"
              />
            </div>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, computed } from "vue";
import { CostCenterType } from "../../types";
import costCenterStore from "../../store/costCenterStore.js";
import { ValidationObserver } from "vee-validate";
import { AppButton, AppSwitch } from "../../../../../components/AppGlobal";

/**
 * Propriedades para o componente
 * @typedef {Object} Props
 * @property {CostCenterType} formData
 */
/** @type {Props} */
const props = defineProps({
  formData: {
    type: Object,
    required: true,
  },
});

/** Eventos emitidos */
const emit = defineEmits([
  "onCancelClick", 
  "onSubmitClick"
]);

/** Store Getters */
const isSaving = computed(() => costCenterStore.getters.getIsSaving());

function onCancelClick() {
  emit("onCancelClick");
}

function onSubmitClick() {
  emit("onSubmitClick");
}
</script>

<style scoped>
.status-button-success {
  background-color: #F2F7F3;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #149E57;
  font-weight: 400;
  padding: 4px 40px;
}
.status-button-danger {
  background-color: #F4C7C3;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #DB4539;
  font-weight: 400;
  padding: 4px 34px
}
.button-cancel {
  cursor: pointer;
  border: 0.5px solid #DB4539;
  border-radius: 8px;
  color: #DB4539;
  padding: 4px 20px;
}
.button-save {
  cursor: pointer;
  border: 0.5px solid #149E57;
  border-radius: 8px;
  color: #149E57;
  padding: 4px 30px;
}
.button-save:hover {
  background: #149E57;
  color: white;
}
.button-save:hover img {
  filter: brightness(0) invert(1);
}
.button-cancel:hover {
  background: #DB4539;
  color: white;
}
.button-cancel:hover img {
  filter: brightness(0) invert(1);
}
</style>
