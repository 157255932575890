import { BaseListFilterType, initBaseListFilterType } from "../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} CostCenterListFilterExtType
 * @property {boolean|null} status
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & CostCenterListFilterExtType)} CostCenterListFilterType
 */
export const CostCenterListFilterType = {};

/**
 * Inicializar CostCenterListFilterType
 *
 * @returns {CostCenterListFilterType}
 */
export const initCostCenterListFilterType = () => {
  return {
    ...initBaseListFilterType(),
    status: null,
  };
}